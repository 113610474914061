.App {
  text-align: center;
  /* filter: 'brightness(80%)'; */
  background-color:#F2F2F2;
  /* background-color: #EAE3C9; */
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px; 
}

::-webkit-scrollbar-thumb {
  background: #C3C3C3;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #C3C3C3;
}

.content-wrapper {
  background-color: transparent;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
} */

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
