.legend {
    padding: 10px;
    background: white;
    line-height: 1.5;
    font-size: 12px;
    color: #333;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
  
  .geojson-tooltip {
    background-color: white;
    color: black;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  /* Custom styles for LayerControl */


  