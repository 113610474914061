:root {
  --switches-bg-color: goldenrod;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: white;
}

.switches-container {
  width: 18rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: white;
  line-height: 2.4rem;
  border-radius: 8px;
  /* margin-left: auto; */
  margin-right: auto;
}

.switches-container b {
  font-size: 14px;
  color: black;
}

.switch-wrapper b {
  font-size: 14px;
  color: white;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 8px;
  background: #69A5EB;
  height: 100%;
  width: 70%;
  justify-content: center;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 70%;
  text-align: center;
  justify-content: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(60%);
}

.switches-container input:nth-of-type(3):checked~.switch-wrapper {
  transform: translateX(120%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(3):checked~.switch-wrapper .switch div:nth-of-type(3) {
  opacity: 1;
}